import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import Header from '../components/Header'
import InputField from '../components/InputField'
import SixResults from '../components/SixResults'
import { env } from '../env'
import { colours } from '../utils/colours'
import { sendRequest } from '../utils/services'
import {
  RESET_GLOBAL,
  UPDATE_ERROR,
  UPDATE_SELECTED_CHAMPIONSHIP,
} from '../container/global/types'
import {
  RESET_DASHBOARD,
  UPDATE_SIMULATIONS_INPUTS,
  UPDATE_SIMULATIONS_OUTPUTS,
} from '../container/dashboard/types'
import {
  RESET_SIMULATION,
  UPDATE_SIMULATIONID,
  UPDATE_DESCRIPTION,
  UPDATE_STATUS,
  UPDATE_NX,
  UPDATE_SERVES_A,
  UPDATE_TEAM_A_ROTS,
  UPDATE_TEAM_B_ROTS,
  UPDATE_TEAM_A_BONUS,
  UPDATE_TEAM_B_BONUS,
  UPDATE_TEAM_A_TACTICS_VARIABLES,
  UPDATE_TEAM_A_TACTICS_VALUES,
  UPDATE_TEAM_B_TACTICS_VARIABLES,
  UPDATE_TEAM_B_TACTICS_VALUES,
  UPDATE_TEAM_A_SUBS_SERVICE,
  UPDATE_TEAM_B_SUBS_SERVICE,
  UPDATE_TEAM_A_SUBS_DIAGONAL,
  UPDATE_TEAM_B_SUBS_DIAGONAL,
  UPDATE_RISULTATO,
  UPDATE_PARZIALI,
  UPDATE_SUBS_LOG,
  UPDATE_TABELLINO,
} from '../container/simulation/types'
import {
  RESET_PLAYERS,
  UPDATE_AVAILABLE_PLAYERS,
  UPDATE_SELECTED_SETTER_A,
  UPDATE_SELECTED_OPPOSITE_A,
  UPDATE_SELECTED_MIDDLE1_A,
  UPDATE_SELECTED_MIDDLE2_A,
  UPDATE_SELECTED_HITTER1_A,
  UPDATE_SELECTED_HITTER2_A,
  UPDATE_SELECTED_LIBERO_A,
  UPDATE_SELECTED_SETTER_B,
  UPDATE_SELECTED_OPPOSITE_B,
  UPDATE_SELECTED_MIDDLE1_B,
  UPDATE_SELECTED_MIDDLE2_B,
  UPDATE_SELECTED_HITTER1_B,
  UPDATE_SELECTED_HITTER2_B,
  UPDATE_SELECTED_LIBERO_B,
  UPDATE_SELECTED_TEAM_A,
  UPDATE_SELECTED_TEAM_B,
} from '../container/players/types'

const BACKEND = `${env.api.endpoint.protocol}://${env.api.endpoint.host}:${env.api.endpoint.port}/api`

const MinWidth = 1100

const StyledTableCell = withStyles(_ => ({
  head: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colours.black,
    backgroundColor: colours.mainLight,
  },
  body: {
    fontSize: 16,
    color: colours.black,
  },
}))(TableCell)

const Dashboard = ({
  user,
  error,
  simulationsInputs,
  simulationsOutputs,
  teamAsubsService,
  teamBsubsService,
  teamAsubsDiagonal,
  teamBsubsDiagonal,
  availablePlayers,
}) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const columns = ['Esecuzione', 'Descrizione', 'Esito', 'Formazioni', '']

  // state to manage the list update
  const [dummyUpdate, setDummyUpdate] = useState(true)

  // state to manage the new description
  const [newDescription, setNewDescription] = useState({
    text: undefined,
    simulationID: undefined,
  })

  // states to manage the six results dialog
  const [openSixResults, setOpenSixResults] = useState(false)
  const [sixResultsData, setSixResultsData] = useState({
    description: undefined,
    sixResults: {},
  })

  const handleClickOpenSixResults = (description, sixResults) => {
    setSixResultsData({ description, sixResults })
    setOpenSixResults(true)
  }

  const handleCloseSixResults = () => setOpenSixResults(false)

  const expPoints = sixResults => {
    const totalPoints =
      sixResults['3-0'] * 3 +
      sixResults['3-1'] * 3 +
      sixResults['3-2'] * 2 +
      sixResults['2-3'] * 1 +
      sixResults['1-3'] * 0 +
      sixResults['0-3'] * 0

    const totalMatches =
      sixResults['3-0'] +
      sixResults['3-1'] +
      sixResults['3-2'] +
      sixResults['2-3'] +
      sixResults['1-3'] +
      sixResults['0-3']

    return (totalPoints / totalMatches).toFixed(1)
  }

  const errorHandler = () => dispatch({ type: UPDATE_ERROR })

  const deleteSimulation = async simulationID => {
    if (!!simulationID) {
      try {
        await sendRequest(
          `${BACKEND}/simulation/delete/${simulationID}`,
          'DELETE',
          null,
          { Authorization: user }
        )
      } catch (err) {
        console.log(err)
      }
      setDummyUpdate(!dummyUpdate)
    }
  }

  // fetch simulations inputs
  useEffect(() => {
    const fetchSimulationsInputs = async () => {
      try {
        const simulationsInputsData = await sendRequest(
          `${BACKEND}/simulation-input/user/${user}`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!simulationsInputsData) return

        dispatch({
          type: UPDATE_SIMULATIONS_INPUTS,
          payload: simulationsInputsData.reverse(),
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchSimulationsInputs()
  }, [user, dummyUpdate, dispatch])

  // fetch simulations outputs
  useEffect(() => {
    const fetchSimulationsOutputs = async () => {
      try {
        const simulationsOutputsData = await sendRequest(
          `${BACKEND}/simulation-output/user/${user}`,
          'GET',
          null,
          { Authorization: user }
        )

        if (!simulationsOutputsData) return

        dispatch({
          type: UPDATE_SIMULATIONS_OUTPUTS,
          payload: simulationsOutputsData,
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchSimulationsOutputs()
  }, [user, dummyUpdate, dispatch])

  const updateDescription = async (simulationID, newValue) => {
    if (!!simulationID) {
      try {
        await sendRequest(
          `${BACKEND}/simulation-input/update/${simulationID}`,
          'POST',
          JSON.stringify({ description: newValue }),
          { 'Content-Type': 'application/json', Authorization: user }
        )
      } catch (err) {
        console.log(err)
      }
      setDummyUpdate(!dummyUpdate)
    }
  }

  // initialize a new simulation
  const initNewSimulation = () => {
    dispatch({
      type: UPDATE_STATUS,
      payload: 'new',
    })
    dispatch({ type: UPDATE_SIMULATIONID })
    dispatch({
      type: UPDATE_DESCRIPTION,
      payload: 'Nuova simulazione',
    })
  }

  // fetch players
  useEffect(() => {
    const championships = simulationsInputs
      .map(o => o.Championship)
      .filter((v, i, a) => a.indexOf(v) === i)

    const fetchPlayers = async championship => {
      try {
        const playersData = await sendRequest(
          `${BACKEND}/players/${championship}`,
          'GET',
          null,
          { Authorization: user }
        )

        // check data
        if (!playersData) return

        dispatch({
          type: UPDATE_AVAILABLE_PLAYERS,
          payload: {
            championship,
            players: playersData.sort((a, b) =>
              a.surname > b.surname ? 1 : -1
            ),
          },
        })
      } catch (err) {
        console.log(err)
      }
    }
    championships.forEach(championship => fetchPlayers(championship))
  }, [dispatch, simulationsInputs, user])

  // selection functions for team A
  const autoSelectA = (role, p) => {
    switch (role) {
      case 'P':
        dispatch({ type: UPDATE_SELECTED_SETTER_A, payload: p })
        break
      case 'O':
        dispatch({ type: UPDATE_SELECTED_OPPOSITE_A, payload: p })
        break
      case 'C1':
        dispatch({ type: UPDATE_SELECTED_MIDDLE1_A, payload: p })
        break
      case 'C2':
        dispatch({ type: UPDATE_SELECTED_MIDDLE2_A, payload: p })
        break
      case 'S1':
        dispatch({ type: UPDATE_SELECTED_HITTER1_A, payload: p })
        break
      case 'S2':
        dispatch({ type: UPDATE_SELECTED_HITTER2_A, payload: p })
        break
      case 'L':
        dispatch({ type: UPDATE_SELECTED_LIBERO_A, payload: p })
        break
      default:
        console.log('Role', role, 'not found!')
    }
  }

  // selection functions for team B
  const autoSelectB = (role, p) => {
    switch (role) {
      case 'P':
        dispatch({ type: UPDATE_SELECTED_SETTER_B, payload: p })
        break
      case 'O':
        dispatch({ type: UPDATE_SELECTED_OPPOSITE_B, payload: p })
        break
      case 'C1':
        dispatch({ type: UPDATE_SELECTED_MIDDLE1_B, payload: p })
        break
      case 'C2':
        dispatch({ type: UPDATE_SELECTED_MIDDLE2_B, payload: p })
        break
      case 'S1':
        dispatch({ type: UPDATE_SELECTED_HITTER1_B, payload: p })
        break
      case 'S2':
        dispatch({ type: UPDATE_SELECTED_HITTER2_B, payload: p })
        break
      case 'L':
        dispatch({ type: UPDATE_SELECTED_LIBERO_B, payload: p })
        break
      default:
        console.log('Role', role, 'not found!')
    }
  }

  // role labels
  const autoLabels = ['P', 'O', 'C1', 'C2', 'S1', 'S2', 'L']

  const subAserOutHandler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: { ...teamAsubsService, out: value },
    })
  }

  const subAserInHandler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: { ...teamAsubsService, in: value },
    })
  }

  const subAserWhenHandler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: { ...teamAsubsService, when: value },
    })
  }

  const subBserOutHandler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: { ...teamBsubsService, out: value },
    })
  }

  const subBserInHandler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: { ...teamBsubsService, in: value },
    })
  }

  const subBserWhenHandler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: { ...teamBsubsService, when: value },
    })
  }

  const subAdiaOut1Handler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: { ...teamAsubsDiagonal, out1: value },
    })
  }

  const subAdiaOut2Handler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: { ...teamAsubsDiagonal, out2: value },
    })
  }

  const subAdiaIn1Handler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: { ...teamAsubsDiagonal, in1: value },
    })
  }

  const subAdiaIn2Handler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: { ...teamAsubsDiagonal, in2: value },
    })
  }

  const subAdiaWhenHandler = value => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: { ...teamAsubsDiagonal, when: value },
    })
  }

  const subBdiaOut1Handler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: { ...teamBsubsDiagonal, out1: value },
    })
  }

  const subBdiaOut2Handler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: { ...teamBsubsDiagonal, out2: value },
    })
  }

  const subBdiaIn1Handler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: { ...teamBsubsDiagonal, in1: value },
    })
  }

  const subBdiaIn2Handler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: { ...teamBsubsDiagonal, in2: value },
    })
  }

  const subBdiaWhenHandler = value => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: { ...teamBsubsDiagonal, when: value },
    })
  }

  // initialize an existing simulation
  const initExistingSimulation = (input, output) => {
    dispatch({
      type: UPDATE_SELECTED_CHAMPIONSHIP,
      payload: input.Championship,
    })
    dispatch({
      type: UPDATE_SIMULATIONID,
      payload: input.simulationID,
    })
    dispatch({
      type: UPDATE_DESCRIPTION,
      payload: input.description,
    })
    dispatch({
      type: UPDATE_STATUS,
      payload: input.status,
    })
    dispatch({ type: UPDATE_NX, payload: input.Nx })
    dispatch({
      type: UPDATE_SELECTED_TEAM_A,
      payload: input.teamAname,
    })
    dispatch({
      type: UPDATE_SELECTED_TEAM_B,
      payload: input.teamBname,
    })

    if (
      input.IDteamA &&
      input.IDteamA.split('-').length === 7 &&
      availablePlayers[input.Championship]
    ) {
      input.IDteamA.split('-').forEach((id, n) => {
        autoSelectA(
          autoLabels[n],
          availablePlayers[input.Championship].find(p => p.playerID === +id)
        )
      })
    }

    if (
      input.IDteamB &&
      input.IDteamB.split('-').length === 7 &&
      availablePlayers[input.Championship]
    ) {
      input.IDteamB.split('-').forEach((id, n) => {
        autoSelectB(
          autoLabels[n],
          availablePlayers[input.Championship].find(p => p.playerID === +id)
        )
      })
    }

    dispatch({ type: UPDATE_SERVES_A, payload: input.ServesA })

    if (input.RotsA && input.RotsA.split('-').length === 5) {
      dispatch({
        type: UPDATE_TEAM_A_ROTS,
        payload: input.RotsA.split('-'),
      })
    }

    if (input.RotsB && input.RotsB.split('-').length === 5) {
      dispatch({
        type: UPDATE_TEAM_B_ROTS,
        payload: input.RotsB.split('-'),
      })
    }

    if (input.BonusA)
      dispatch({ type: UPDATE_TEAM_A_BONUS, payload: input.BonusA })
    if (input.BonusB)
      dispatch({ type: UPDATE_TEAM_B_BONUS, payload: input.BonusB })

    if (input.TactA && input.TactA.split('-').length === 2) {
      const TactAs = input.TactA.split('-')
      dispatch({ type: UPDATE_TEAM_A_TACTICS_VARIABLES, payload: TactAs[0] })
      dispatch({ type: UPDATE_TEAM_A_TACTICS_VALUES, payload: TactAs[1] })
    }

    if (input.TactB && input.TactB.split('-').length === 2) {
      const TactBs = input.TactB.split('-')
      dispatch({ type: UPDATE_TEAM_B_TACTICS_VARIABLES, payload: TactBs[0] })
      dispatch({ type: UPDATE_TEAM_B_TACTICS_VALUES, payload: TactBs[1] })
    }

    if (
      input.SubAser &&
      input.SubAser.split('-').length === 3 &&
      availablePlayers[input.Championship]
    ) {
      const SubAsers = input.SubAser.split('-')
      subAserOutHandler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAsers[0]
        )
      )
      subAserInHandler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAsers[1]
        )
      )
      subAserWhenHandler(SubAsers[2])
    }

    if (
      input.SubBser &&
      input.SubBser.split('-').length === 3 &&
      availablePlayers[input.Championship]
    ) {
      const SubBsers = input.SubBser.split('-')
      subBserOutHandler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBsers[0]
        )
      )
      subBserInHandler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBsers[1]
        )
      )
      subBserWhenHandler(SubBsers[2])
    }

    if (
      input.SubAdia &&
      input.SubAdia.split('-').length === 5 &&
      availablePlayers[input.Championship]
    ) {
      const SubAdias = input.SubAdia.split('-')
      subAdiaOut1Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAdias[0]
        )
      )
      subAdiaOut2Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAdias[1]
        )
      )
      subAdiaIn1Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAdias[2]
        )
      )
      subAdiaIn2Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubAdias[3]
        )
      )
      subAdiaWhenHandler(SubAdias[4])
    }

    if (
      input.SubBdia &&
      input.SubBdia.split('-').length === 5 &&
      availablePlayers[input.Championship]
    ) {
      const SubBdias = input.SubBdia.split('-')
      subBdiaOut1Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBdias[0]
        )
      )
      subBdiaOut2Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBdias[1]
        )
      )
      subBdiaIn1Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBdias[2]
        )
      )
      subBdiaIn2Handler(
        availablePlayers[input.Championship].find(
          p => p.playerID === +SubBdias[3]
        )
      )
      subBdiaWhenHandler(SubBdias[4])
    }

    if (['complete', 'error'].includes(input.status)) {
      if (input.status === 'error') {
        dispatch({ type: UPDATE_RISULTATO, payload: 'Errore' })
        dispatch({ type: UPDATE_PARZIALI, payload: [] })
        dispatch({ type: UPDATE_TABELLINO })
        dispatch({ type: UPDATE_SUBS_LOG })
      } else {
        if (input.Nx === 1) {
          dispatch({
            type: UPDATE_RISULTATO,
            payload:
              output.Score.SetsA !== undefined &&
              output.Score.SetsB !== undefined
                ? `${output.Score.SetsA} - ${output.Score.SetsB}`
                : 'Errore',
          })
          let simulationParziali = []
          for (let i = 0; i < output.Score.PointsA.length; i += 1) {
            simulationParziali.push(
              `${output.Score.PointsA[i]} - ${output.Score.PointsB[i]}`
            )
          }
          dispatch({ type: UPDATE_PARZIALI, payload: simulationParziali })
        } else {
          dispatch({
            type: UPDATE_RISULTATO,
            payload:
              output.Apoints !== undefined && output.Bpoints !== undefined
                ? `Punteggio medio ${output.Apoints} - ${output.Bpoints}`
                : 'Errore',
          })
          dispatch({
            type: UPDATE_PARZIALI,
            payload:
              output.Awins !== undefined && output.Bwins !== undefined
                ? [`${output.Awins}% di vittoria per la squadra A`]
                : [],
          })
        }
        dispatch({ type: UPDATE_TABELLINO, payload: output.Stats })
        dispatch({ type: UPDATE_SUBS_LOG, payload: output.SubsLog })
      }
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        onClose={errorHandler}
        message={error}
        action={
          <>
            <IconButton size='small' color='inherit' onClick={errorHandler}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <Header MinWidth={MinWidth} />
      &nbsp;
      <Grid container item alignItems='center' style={{ minWidth: MinWidth }}>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={5}>
          <center>
            <Link
              to='/simulation'
              style={{ textDecoration: 'none' }}
              onClick={() => {
                initNewSimulation()
              }}
            >
              <IconButton>
                Nuova simulazione &nbsp;
                <AddIcon />
              </IconButton>
            </Link>
          </center>
        </Grid>
        <Grid item xs={8} md={4}>
          <center>
            <IconButton
              onClick={() => {
                setDummyUpdate(!dummyUpdate)
                dispatch({ type: UPDATE_ERROR, payload: 'Elenco aggiornato.' })
              }}
            >
              Aggiorna elenco &nbsp;
              <RefreshIcon />
            </IconButton>
          </center>
        </Grid>
        <Grid item xs={4} md={2}>
          <center>
            <Link
              to='/'
              style={{ textDecoration: 'none' }}
              onClick={() => {
                dispatch({ type: RESET_GLOBAL })
                dispatch({ type: RESET_DASHBOARD })
                dispatch({ type: RESET_SIMULATION })
                dispatch({ type: RESET_PLAYERS })
              }}
            >
              <IconButton>
                Esci &nbsp;
                <ExitToAppIcon />
              </IconButton>
            </Link>
          </center>
        </Grid>
      </Grid>
      &nbsp;
      <TableContainer component={Paper} style={{ minWidth: MinWidth }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell key={column} align='center'>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!simulationsInputs &&
              !!simulationsOutputs &&
              simulationsInputs.map((input, index) => {
                const output = simulationsOutputs.find(
                  o => o.simulationID === input.simulationID
                )
                return (
                  <TableRow
                    key={index}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        (input.Nx === 1 && colours.yellowLight) ||
                        (index % 2 === 1 && colours.whiteGrey) ||
                        colours.white,
                    }}
                    onClick={() => {
                      initExistingSimulation(input, output)
                      history.push('/simulation')
                    }}
                  >
                    <StyledTableCell
                      align='center'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {new Date(input.timeInsert)?.toLocaleDateString()}
                      &nbsp;
                      {new Date(input.timeInsert)?.toLocaleTimeString(
                        undefined,
                        { hour: '2-digit', minute: '2-digit' }
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      onClick={e => e.stopPropagation()}
                    >
                      <InputField
                        id={input.simulationID}
                        value={
                          input.simulationID === newDescription.simulationID
                            ? newDescription.text
                            : input.description
                        }
                        onChange={newValue => {
                          setNewDescription({
                            text: newValue,
                            simulationID: input.simulationID,
                          })
                        }}
                        onBlur={newValue => {
                          updateDescription(input.simulationID, newValue)
                          setDummyUpdate(!dummyUpdate)
                        }}
                        fullWidth
                        placeholder='Aggiungi descrizione'
                        size='small'
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      onClick={e => e.stopPropagation()}
                    >
                      {(input.status === 'complete' && (
                        <center>
                          <Tooltip title='Dettaglio'>
                            <Button
                              onClick={() =>
                                handleClickOpenSixResults(
                                  input.description,
                                  output.sixResults
                                )
                              }
                            >
                              <Grid container item direction='column'>
                                {(!!output && (
                                  <>
                                    <Grid item>
                                      <b>
                                        {(output.Nx === 1 &&
                                          `${output.Score.SetsA}-${output.Score.SetsB}`) ||
                                          `${output.Awins}%`}
                                      </b>
                                    </Grid>
                                    <Grid item>
                                      {`${expPoints(output.sixResults)} PT`}
                                    </Grid>
                                  </>
                                )) ||
                                  '-'}
                              </Grid>
                            </Button>
                          </Tooltip>
                        </center>
                      )) ||
                        input.status}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <Grid container item direction='column' spacing={1}>
                        <Grid item xs>
                          {!!output &&
                            output.Stats &&
                            output.Stats.slice(
                              0,
                              output.Stats.map(st => st.Player).indexOf('Ateam')
                            )
                              .map(p => p.Player.slice(0, -3))
                              .join(', ')}
                        </Grid>
                        <Grid item xs>
                          {!!output &&
                            output.Stats &&
                            output.Stats.slice(
                              output.Stats.map(st => st.Player).indexOf(
                                'Ateam'
                              ) + 1,
                              output.Stats.map(st => st.Player).indexOf('Bteam')
                            )
                              .map(p => p.Player.slice(0, -3))
                              .join(', ')}
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      onClick={e => e.stopPropagation()}
                    >
                      {input.status !== 'running' && (
                        <IconButton
                          size='small'
                          style={{ color: colours.redDark }}
                          onClick={() => deleteSimulation(input.simulationID)}
                        >
                          {(input.status === 'waiting' && (
                            <Tooltip title='Annulla'>
                              <CloseIcon />
                            </Tooltip>
                          )) || (
                            <Tooltip title='Elimina'>
                              <DeleteIcon />
                            </Tooltip>
                          )}
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <SixResults
        open={openSixResults}
        handleClose={handleCloseSixResults}
        data={sixResultsData}
      />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.global.user,
  error: state.global.error,
  simulationsInputs: state.dashboard.simulationsInputs,
  simulationsOutputs: state.dashboard.simulationsOutputs,
  teamAsubsService: state.simulation.teamAsubsService,
  teamBsubsService: state.simulation.teamBsubsService,
  teamAsubsDiagonal: state.simulation.teamAsubsDiagonal,
  teamBsubsDiagonal: state.simulation.teamBsubsDiagonal,
  availablePlayers: state.players.availablePlayers,
})

const ConnectedDashboard = connect(mapStateToProps)(Dashboard)

export default ConnectedDashboard
